<template>
  <v-container fluid>

      <v-card>
        <v-card-title>
          {{$t('auth.verify_email')}}
        </v-card-title>
        
          <v-form @submit.prevent="send" v-model="valid" @keydown="form.onKeydown($event)">

          <v-card-text>
            <v-alert v-if="status" outlined prominent border="left"  type="success">
            {{status}}
            </v-alert>

              <v-text-field
                v-model="form.email"
                :rules="emailRules"
                :label="$t('auth.email')"
                required
                solo
                clearable
                append-icon="mdi-account"
              ></v-text-field>

          </v-card-text>
          <v-card-actions>
              <v-btn type="submit" rounded color="primary" :disabled="!valid" :loading="form.busy">
                {{ $t('auth.send_verification_link') }}
              </v-btn>
          </v-card-actions>

          <v-alert
            v-if="error"
            color="orange"
            outlined
            shaped
            type="info"
            >
            <p>{{error.message}}</p>

            <p v-for="(e,index) in error.errors.email" :key="index">
              {{e}}
            </p>

          </v-alert>

        </v-form>
      </v-card>

  </v-container>
</template>

<script>
import Form from 'vform'
import i18n from '@/i18n'

export default {
  middleware: 'guest',

  metaInfo () {
    return { title: i18n.t('auth.verify_email') }
  },

  data() {
    return {
    status: '',
    error: null,
    valid: false,
    form: new Form({
      email: ''
    }),
      emailRules: [
            v => !!v || i18n.t('auth.email_required'),
            v => /.+@.+/.test(v) || i18n.t('auth.email_valid'),
      ],
    }
  },

  created () {
    if (this.$route.query.email) {
      this.form.email = this.$route.query.email
    }
  },

  methods: {
    async send () {
      this.error = null;
      await this.$http.get('sanctum/csrf-cookie')
      await this.form.post('/api/email/resend')
      .then((response) => {
        this.status = response.data.status;
      }).catch((error) => {
            this.error = error.data;
          });
      //this.status = data.status

      this.form.reset()
    }
  }
}
</script>
